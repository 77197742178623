@import "../../scss/variables";

.hero-section {
  padding: 60px 0;

  .hero__content {
    .hero__content__verticall_lebel {
      transform: rotate(90deg);
      transform-origin: left top 0;
      position: relative;

      .text-rotate {
        position: absolute;
        left: 0;
        top: 0;
        transform: rotate(-180deg);
        margin-top: -20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      span {
        color: $black-100;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: $f-regular;

        &.line_dot {
          width: 3px;
          height: 3px;
          border-radius: 6px;
          background-color: $black-100;
          display: inline-block;
          margin: 10px;
        }
      }
    }

    .hero__content__title {
      padding-left: 80px;
      padding-top: 80px;

      h1 {
        font-size: 42px;
        line-height: 56px;
        font-weight: $f-light;

        .tx_last_line {
          width: 2px;
          height: 35px;
          background-color: $black;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .hero__content__desc {
      padding-left: 80px;

      p {
        font-size: 14px;
        line-height: 24px;
        max-width: 550px;
        margin-top: 15px;
      }
    }

    .hero__content__social {
      margin-top: 80px;

      ul {
        li {
          display: block;

          a {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            margin: 15px 0;
            background-color: $black;
            color: $white;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background-color 300ms ease-in-out;

            &:hover {
              background-color: $gray-700;
            }
          }
        }
      }
    }
  }

  &.header_dark {
    background-color: $black;

    .hero__content {
      .hero__content__verticall_lebel {
        span {
          a {
            color: $white;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              bottom: -18px;
              left: -40px;
              height: 50px;
              width: 1px;
              background-color: $gray-400;
              display: block;
              z-index: 999;
              transform: rotate(90deg);
            }
          }
        }
      }

      .hero__content__desc {
        padding-left: 0px;
        padding-top: 300px;
        h3 {
          font-size: 42px;
          font-weight: $f-light;
          color: $white;
        }

        p {
          color: $white;
        }
      }
    }
    .container {
      position: relative;
    }
    .hero__showcase {
      &--quote {
        text-align: right;
        img {
          display: block;
          float: right;
          margin-top: 100px;
        }
      }
    }
    .quote__box {
      background-color: $white;
      min-width: 440px;
      padding: 90px 50px;
      position: absolute;
      right: 0;
      bottom: -200px;
      box-shadow: 0 3px 90px rgba(0, 0, 0, 0.1);
      z-index: 99999;
      span {
        display: block;
        font-size: 12px;
        font-weight: $f-light;
      }
      a {
        font-size: 18px;
        color: $black;
        font-weight: $f-regular;
        display: block;

        &.quote_mail {
          margin-bottom: 40px;
        }
      }
      address {
        font-size: 18px;
        color: $black;
        font-weight: $f-regular;
        display: block;
        line-height: 26px;
      }
      ul {
        margin-top: 15px;
        li {
          display: inline-block;
          a {
            margin-right: 15px;
            &:hover {
              color: #8e8e8e;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 767px) {
  .hero-section {
    padding: 60px 0;

    .hero__content {
      .hero__content__title {
        padding-left: 40px;
        padding-top: 40px;

        h1 {
          font-size: 20px;
          line-height: 32px;

          .tx_last_line {
            height: 24px;
          }
        }
      }

      .hero__content__desc {
        padding-left: 40px;

        p {
          font-size: 12px;
        }
      }

      .hero__content__social {
        margin-top: 120px;
      }
    }

    .hero__showcase {
      text-align: center;
      margin-top: -160px;

      img {
        width: 60%;
        margin: 0 auto;
        display: inline-block;
      }
      &--quote {
        position: absolute;
        top: -100px;
        img {
          margin-top: 0px;
        }
      }
    }
    &.header_dark {
      background-color: $black;
      padding-top: 0px;
      .hero__content {
        .hero__content__desc {
          padding-left: 30px;
          padding-top: 50px;
          h3 {
            font-size: 32px;
          }
        }
      }

      .quote__box {
        display: none;
      }
    }
  }
}

@media all and (min-width: 767.1px) and (max-width: 1024px) {
  .hero-section {
    padding: 20px 0;
    .hero__content {
      .hero__content__title {
        h1 {
          font-size: 24px;
          line-height: 36px;
        }
      }
    }

    .hero__showcase {
      img {
        width: 100%;
        margin-top: 100px;
      }
      &--quote {
        img {
          margin-top: 0px !important;
        }
      }
    }
    &.header_dark {
      background-color: $black;

      .hero__content {
        .hero__content__desc {
          padding-left: 0px;
          padding-top: 260px;
          h3 {
            font-size: 42px;
            font-weight: $f-light;
            color: $white;
          }

          p {
            color: $white;
          }
        }
      }
      .container {
        position: relative;
      }
      .quote__box {
        background-color: $white;
        min-width: 300px;
        padding: 40px 30px;
        position: absolute;
        right: 0;
        bottom: -100px;
        box-shadow: 0 3px 90px rgba(0, 0, 0, 0.1);
        z-index: 99999;
      }
    }
  }
}
