@import "../../scss/variables";

.our_work-section {
  padding: 50px 0;
  position: relative;

  .bg-shape {
    background-image: url("../../assets/images/our_work-shape.svg");
    background-repeat: no-repeat;
    background-position: right top;
  }

  .section_head_level {
    display: flex;
    align-items: center;
    transform: rotate(-90deg);
    position: absolute;
    top: 150px;
    left: -64px;

    .level-line {
      width: 50px;
      height: 1px;
      background-color: $gray-600;
      display: block;
      margin-right: 10px;
    }

    p {
      font-size: 12px;
      text-transform: uppercase;
      margin: 0;
      color: $gray-600;
    }
  }

  .section__head {
    h3 {
      font-size: 42px;
      font-weight: $f-light;
      line-height: 48px;
      max-width: 780px;
      padding-left: 40px;
      margin-top: 200px;
    }
  }

  .custom_space {
    margin-right: -30px;
    margin-left: -30px;

    .col-md-6 {
      padding-right: 30px;
      padding-left: 30px;
    }
  }

  .our_work__card {
    margin-bottom: 130px;

    .btn__wrap {
      margin-top: 80px;
      margin-left: 40px;
      display: inline-block;
      background-image: url("../../assets/images/btn-shape.svg");
      background-repeat: no-repeat;
      background-position: bottom right;
      padding-right: 20px;
      padding-bottom: 20px;

      .btn {
        background-color: $white;
        border-color: $black;
        border-radius: 0;
        width: 220px;
        height: 60px;
        font-size: 12px;
        font-weight: $f-medium;
        color: $black;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          box-shadow: 10px 10px 0 $gray-300;
        }

        .view__line {
          width: 20px;
          height: 1px;
          background-color: $organge-100;
          display: block;
          margin-left: 10px;
        }
      }
    }

    .our_work__card__cover {
      overflow: hidden;

      img {
        width: 100%;
        transition: all 300ms ease-in-out;
        cursor: pointer;
      }

      &:hover {
        img {
          transform: scale(1.05);
        }
      }
    }

    .our_work__card__content {
      padding: 20px 0;

      h3 {
        font-size: 24px;
        line-height: 36px;
        font-weight: $f-light;
        margin-bottom: 0;
      }

      p {
        font-size: 12px;
        color: $gray-700;
      }
    }
  }
}

@media all and (max-width: 991px) {
  .our_work-section {
    padding: 50px 0;
    position: relative;

    .bg-shape {
      background-image: url("../../assets/images/our_work-shape.svg");
      background-repeat: no-repeat;
      background-position: right top;
    }

    .custom_space {
      margin-right: -15px;
      margin-left: -15px;

      .col-md-6 {
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    .section_head_level {
      top: 350px;
    }

    .section__head {
      h3 {
        font-weight: $f-light;
        font-size: 32px;
        line-height: 46px;
        max-width: 780px;
        padding-left: 40px;
        margin-top: 300px;
      }
    }

    .our_work__card {
      margin-bottom: 50px;
    }
  }
}

@media all and (min-width: 1023px) and (max-width: 1100px) {
  .our_work-section {
    .section__head {
      h3 {
        font-size: 35px;
        line-height: 46px;
        margin-bottom: 50px;
      }
    }
  }
}
