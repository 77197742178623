@import "../../scss/variables";

.services-section {
  padding: 30px 0 60px 0;
  .services__card {
    padding: 30px;
    transition: all 300ms ease-in-out;
    .services__card__icon {
      margin-bottom: 20px;
      img {
        &.icon-dark {
          display: block;
        }
        &.icon-white {
          display: none;
        }
      }
    }
    .services__card__title {
      h3 {
        font-size: 18px;
        font-weight: $f-regular;
      }
    }
    .services__card__desc {
      p {
        color: $gray-400;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0;
      }
    }
    &:hover {
      background-color: $black;
      .services__card__icon {
        img {
          &.icon-dark {
            display: none;
          }
          &.icon-white {
            display: block;
          }
        }
      }
      .services__card__title {
        h3 {
          color: $white;
        }
      }
      .services__card__desc {
        p {
          color: $white;
        }
      }
    }
  }
}

@media all and (max-width: 991px) {
  .services-section {
    .services__card {
      padding-right: 60px;
      margin-bottom: 40px;
    }
  }
}
@media all and (min-width: 767.1px) and (max-width: 1024px) {
  .services-section {
    padding: 0px 0 60px 0;
  }
}
