$font-family: 'Roboto', sans-serif;
$font-size: 14px;
$line-height: 22px;
$f-light:300;
$f-regular:400;
$f-medium:500;
$f-sm-bold:600;
$f-bold:700;
$f-black:900;



// colors
$black: #000000;
$black-100: rgba(0,0,0,0.3);
$black-200:#273444;
$black-300:#1B1B1B;
$white:#ffffff;

$gray-100:#EAEAEA;
$gray-200:#F8F8F8;
$gray-300:#D3D3D3;
$gray-400:#C0C0C0;
$gray-500:#E9E9E9;
$gray-600:#8A8A8A;
$gray-700:#6C6C6C;
$gray-800:#757575;
$gray-900:#636363;
$gray-1000:#A3A3A3;

$yellow-100:#FFF8D3;

$organge-100:#FD7043;

$green-100:#49B73D;
$green-200:#3EDF82;

$txtClr: $black;
$bgColor:$white;


$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1320px
);