@import "../../scss/variables";

.our_clients-section {
  margin-top: -120px;
  .section__head {
    position: relative;
    padding-left: 60px;
    padding-top: 100px;
    .section-shape {
      position: absolute;
      right: 0;
      top: -100px;
    }
    .section_head_level {
      display: flex;
      align-items: center;
      transform: rotate(-90deg);
      position: absolute;
      top: 150px;
      left: -64px;

      .level-line {
        width: 50px;
        height: 1px;
        background-color: $gray-600;
        display: block;
        margin-right: 10px;
      }

      p {
        font-size: 12px;
        text-transform: uppercase;
        margin: 0;
        color: $gray-600;
      }
    }
    h3 {
      font-size: 42px;
      font-weight: $f-light;
      line-height: 48px;
      max-width: 780px;
    }
    p {
      max-width: 890px;
      font-size: 18px;
      line-height: 26px;
    }
  }

  .custom_margin {
    margin-top: 100px;
  }
  .client__card {
    background-color: $gray-200;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease-in-out;
    border-bottom: 1px solid $white;
    border-right: 1px solid $white;
    &:hover {
      background-color: $white;
      box-shadow: 0 3px 90px rgba(0, 0, 0, 0.16);
      z-index: 99999;
      position: relative;
    }
  }
}

@media all and (max-width: 991px) {
  .our_clients-section {
    margin-top: -20px;
    .section__head {
      position: relative;
      padding-top: 0px;
      .section-shape {
        display: none;
      }
      .section_head_level {
        top: 50px;
      }
      h3 {
        font-size: 32px;
        line-height: 36px;
      }
    }
  }
}
@media all and (min-width: 767px) and (max-width: 991px) {
  .our_clients-section {
    .section__head {
      .section-shape {
        display: block;
        img {
          width: 40%;
          float: right;
        }
      }
    }
  }
}
@media all and (min-width: 1023px) and (max-width: 1100px) {
  .our_clients-section {
    .section__head {
      .section-shape {
        display: block;
        img {
          width: 50%;
          float: right;
        }
      }
    }
  }
}
