@import "../../scss/variables";

.project-type {
  margin-top: 140px;
  padding-bottom: 300px;

  .project_type_title {
    margin-bottom: 30px;

    h3 {
      font-size: 20px;
      font-weight: $f-regular;
    }

    p {
      font-size: 12px;
      margin-top: 15px;
    }
  }

  .project_type_box__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .project_type_box {
    width: 226px;
    height: 176px;
    background-color: $gray-200;
    border: 4px solid $gray-200;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 300ms ease-in-out;
    position: relative;
    cursor: pointer;

    .custom-checkbox {
      position: absolute;
      right: 6px;
      top: 6px;
      z-index: 999;

      .custom-control-label {
        &::before {
          width: 22px;
          height: 22px;
          border: #a3a3a3 solid 1px;
          border-radius: 0;
        }

        &::after {
          width: 22px;
          height: 22px;
        }
      }

      .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #000;
        border-width: 2px;
        background-color: #fff;
      }

      .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("../../assets/images/black-check.png");
      }
    }

    .project_type_box__icon {
      margin-bottom: 20px;
      height: 60px;

      img {
        &.img-type-gray {
          display: block;
        }

        &.img-type-white {
          display: none;
        }

        &.img-type-black {
          display: none;
        }
      }
    }

    h3 {
      font-size: 18px;
      font-weight: $f-regular;
      color: $gray-1000;
    }

    &:hover {
      background-color: #f5f5f5;
      box-shadow: 0 3px 40px rgba(0, 0, 0, 0.2);
      border-color: #f5f5f5;

      .project_type_box__icon {
        img {
          &.img-type-gray {
            display: block;
          }

          &.img-type-white {
            display: none;
          }
        }
      }
    }
  }

  .project_type_box--active {
    background-color: $green-200;
    border-color: #000;

    .project_type_box__icon {
      img {
        &.img-type-gray {
          display: none;
        }

        &.img-type-white {
          display: none;
        }

        &.img-type-black {
          display: block;
        }
      }
    }

    h3 {
      color: $black;
    }

    &:hover {
      background-color: $green-200;
      border-color: #000;

      .project_type_box__icon {
        img {
          &.img-type-gray {
            display: none;
          }

          &.img-type-white {
            display: none;
          }

          &.img-type-black {
            display: block;
          }
        }
      }

      h3 {
        color: $black;
      }
    }
  }

  .type__form {
    position: relative;

    label {
      font-size: 20px;
      font-weight: $f-regular;
      display: block;
      margin-bottom: 10px;

      span {
        color: red;
        display: inline-block;
        margin-left: 5px;
      }
    }

    .form-control {
      border-radius: 0;
      height: 75px;
      padding: 15px;
      margin-bottom: 70px;
      color: $black;
      font-size: 20px;
      font-weight: $f-medium;

      &::placeholder {
        color: #d7d7d7;
      }

      &-webkit-::placeholder {
        color: #d7d7d7;
      }

      &-moz-::placeholder {
        color: #d7d7d7;
      }

      &:focus {
        border-color: $black;
        box-shadow: none;
      }
    }

    .custom-select {
      border-radius: 0;
      height: 75px;
      padding: 15px;
      margin-bottom: 70px;
      font-size: 20px;
      font-weight: $f-medium;
      color: #d7d7d7;

      &::placeholder {
        color: #d7d7d7;
      }

      &-webkit-::placeholder {
        color: #d7d7d7;
      }

      &-moz-::placeholder {
        color: #d7d7d7;
      }

      &:focus {
        border-color: $black;
        box-shadow: none;
        color: #000;
      }

      &.colorBlack {
        border-color: $black;
        box-shadow: none;
        color: #000;
      }
    }

    textarea {
      &.form-control {
        height: 180px;
        font-size: 20px;
        margin-bottom: 40px;
        font-size: 20px;
        font-weight: $f-medium;
      }
    }

    span {
      display: block;
      font-size: 12px;
      color: #8e8e8e;
    }

    .custom-control {
      margin-top: 30px;

      .custom-control-label {
        font-size: 12px;
        color: #8e8e8e;
        line-height: 27px;

        &::before {
          width: 1.2rem;
          height: 1.2rem;
          border: #707070 solid 1px;
          border-radius: 0;
        }

        &::after {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }

    .btn__wrap {
      margin-top: 50px;
      display: inline-block;
      background-image: url("../../assets/images/btn-shape.svg");
      background-repeat: no-repeat;
      background-position: bottom right;
      padding-right: 20px;
      padding-bottom: 20px;

      .btn {
        background-color: $white;
        border-color: $black;
        border-radius: 0;
        width: 220px;
        height: 60px;
        font-size: 12px;
        font-weight: $f-medium;
        color: $black;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 30px;

        &:hover {
          box-shadow: 10px 10px 0 $gray-300;
        }

        .view__line {
          width: 20px;
          height: 1px;
          background-color: $organge-100;
          display: block;
          margin-left: 10px;
        }
      }

      .has_error {
        display: none;
      }
    }

    &--error {
      .form-control {
        border-color: red;
        box-shadow: 0 3px 20px rgba(255, 29, 29, 0.16);
      }

      .custom-select {
        border-color: red;
        box-shadow: 0 3px 20px rgba(255, 29, 29, 0.16);
      }

      textarea {
        &.form-control {
          border-color: red;
          box-shadow: 0 3px 20px rgba(255, 29, 29, 0.16);
        }
      }

      .has_error {
        display: block;
        font-size: 13px;
        color: red;
        top: 120px;
        position: absolute;
      }
    }
  }
}

.mobile__only {
  display: none;
}

@media all and (max-width: 500px) {
  .container,
  .container-fluid,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 370px;
  }
}

@media all and (max-width: 767px) {
  .header-section.header_dark {
    position: relative;
    z-index: 9999999;
  }

  .project-type {
    margin-top: 40px;
    padding-bottom: 160px;

    .type__form label {
      font-size: 14px;
    }

    .mt-5 {
      margin-top: 10px !important;
    }

    .project_type_box__wrap {
      flex-wrap: wrap;
      justify-content: center;
    }

    .project_type_box {
      height: 81px;
      margin-bottom: 20px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 30px;
      width: 340px;

      .custom-checkbox {
        top: 20px;
      }

      .project_type_box__icon {
        margin-right: 25px;
        width: 68px;
        margin-bottom: 0;
        height: auto;
      }

      &:nth-child(1) {
        .project_type_box__icon {
          img {
            width: 42px;
          }
        }
      }

      &:nth-child(2) {
        .project_type_box__icon {
          img {
            width: 68px;
          }
        }
      }

      &:nth-child(3) {
        .project_type_box__icon {
          img {
            width: 60px;
          }
        }
      }

      &:nth-child(4) {
        .project_type_box__icon {
          img {
            width: 40px;
          }
        }
      }

      h3 {
        margin: 0;
      }
    }

    .type__form {
      .form-control {
        height: 60px;
        margin-bottom: 40px;
        font-size: 18px;
        font-weight: $f-regular;

        .custom-control-label {
          line-height: 20px;
        }
      }

      .custom-select {
        height: 60px;
        margin-bottom: 40px;
        font-size: 18px;
        font-weight: $f-regular;
      }

      textarea {
        &.form-control {
          margin-bottom: 40px;
          font-size: 18px;
          font-weight: $f-regular;
        }
      }

      .btn__wrap {
        margin-top: 20px;
      }

      &--error {
        .form-control {
          border-color: red;
          margin-bottom: 60px;
        }

        .has_error {
          top: 100px;
        }

        .custom-select {
          margin-bottom: 60px;
        }
      }
    }
  }

  .mobile__only {
    display: block;
    text-align: center;
    padding-bottom: 160px;

    .quote__box {
      background-color: $white;

      span {
        display: block;
        font-size: 12px;
        font-weight: $f-light;
      }

      a {
        font-size: 18px;
        color: $black;
        font-weight: $f-regular;
        display: block;

        &.quote_mail {
          margin-bottom: 40px;
        }
      }

      address {
        font-size: 18px;
        color: $black;
        font-weight: $f-regular;
        display: block;
        line-height: 26px;
      }

      ul {
        margin-top: 15px;

        li {
          display: inline-block;

          a {
            margin-right: 15px;

            &:hover {
              color: #8e8e8e;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 767.1px) and (max-width: 1024px) {
  .project-type {
    padding-bottom: 160px;

    .type__form label {
      font-size: 14px;
    }

    .project_form {
      .mt-5 {
        margin-top: 40px !important;
      }
    }

    .project_type_box {
      height: 130px;
      width: 165px;

      .project_type_box__icon {
        margin-bottom: 10px;
        margin-top: 10px;
        height: 50px;
      }

      &:nth-child(1) {
        .project_type_box__icon {
          img {
            width: 42px;
          }
        }
      }

      &:nth-child(2) {
        .project_type_box__icon {
          img {
            width: 68px;
          }
        }
      }

      &:nth-child(3) {
        .project_type_box__icon {
          img {
            width: 60px;
          }
        }
      }

      &:nth-child(4) {
        .project_type_box__icon {
          img {
            width: 40px;
          }
        }
      }

      h3 {
        font-size: 14px;
      }
    }

    .type__form {
      .form-control {
        height: 57px;
        margin-bottom: 52px;
        font-size: 18px;
        font-weight: $f-regular;

        .custom-control-label {
          line-height: 20px;
        }
      }

      .custom-select {
        height: 57px;
        margin-bottom: 52px;
        font-size: 18px;
        font-weight: $f-regular;
      }

      textarea {
        &.form-control {
          margin-bottom: 40px;
          font-size: 18px;
          font-weight: $f-regular;
        }
      }

      .btn__wrap {
        margin-top: 20px;
      }

      &--error {
        .form-control {
          border-color: red;
        }

        .has_error {
          top: 95px;
        }
      }
    }
  }
}

@media all and (min-width: 992px) and (max-width: 1100px) {
  .project-type {
    .col-lg-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .offset-lg-1 {
      margin-left: 0;
    }
  }
}
